<template>
  <div class="p-create">

    <form action="" @submit.prevent="create">
      <v-row>
        <v-col md="8" offset-md="2">
          <TextInput id="title" :rules="validationRules.title" label="Template Title *" v-model="titleInput" />
        </v-col>
        <v-col cols="12">
          <TextArea id="description" label="Optional template description" v-model="descriptionInput" />
        </v-col>
        <v-col cols="12" md="6">
          <TextInput id="to" type="email" label="To" v-model="toInput" />
        </v-col>
        <v-col cols="12" md="6">
          <TextInput id="subject" label="Subject" v-model="subjectInput" />
        </v-col>

        <v-col cols="12">
          <TextInput id="cc" type="text" label="CC Emails:" v-model="ccInput" />
        </v-col>
      </v-row>

      <div class="p-create__placeholders">
        <p>In your message below, you can insert curly braces <code>{}</code> to add placeholders. e.g <code>My name is {name}</code>.</p>
        <p>Placeholders found:
          <v-chip class="mr-1" color="primary" filter v-for="placeholder of placeholders" :key="placeholder">
            {{ placeholder.slice(1, -1) }}
          </v-chip>
        </p>
        <p>You can click the Sample Data button below to see an example of this.</p>
      </div>

      <v-sheet
        elevation="12"
        class="pa-12 mb-6"
      >
        <TextArea messages="You can use curly braces {} to add placeholders that will be replaced, e.g My name is {name}" id="message" :rules="validationRules.message" label="Your email message *" v-model="messageInput" />
      </v-sheet>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn type="submit" block :loading="isLoading" x-large color="primary">Save email template</v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
          <v-btn type="button" block x-large color="secondary" @click.prevent="loadSampleTemplate">Load Sample Data</v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn type="button" block x-large color="error" @click.prevent="clearForm">Clear Form</v-btn>
        </v-col>
      </v-row>
    </form>
    <div id="rendered" />
  </div>
</template>

<script>
import slugify from 'slugify'
import { sampleTitle, sampleDescription, sampleTo, sampleSubject, sampleMessage } from '@/utils/sample-template'
import Vue from 'vue'
import getPlaceholders from '@/utils/get-placeholders'
import { createTemplate, analytics } from '@/plugins/firebase'

export default Vue.extend({
  name: 'Create',

  persist: ['titleInput', 'descriptionInput', 'toInput', 'subjectInput', 'messageInput'],

  data () {
    return {
      isLoading: false,
      titleInput: '',

      descriptionInput: '',

      toInput: '',
      ccInput: '',
      subjectInput: '',
      messageInput: '',

      validationRules: {
        title: [
          value => !!value || 'Required.',
          value => (value || '').length <= 30 || 'Max 30 characters'
        ],
        message: [
          value => !!value || 'Required.',
          value => (value || '').length >= 30 || 'Min 30 characters'
        ]
      }
    }
  },

  computed: {
    formData () {
      return {
        title: this.titleInput,
        description: this.descriptionInput,
        to: this.toInput,
        cc: this.ccInput,
        subject: this.subjectInput,
        message: this.messageInput
      }
    },
    placeholders () {
      return getPlaceholders(this.messageInput)
    },
    slug () {
      return slugify(this.titleInput).substring(0, 50).toLowerCase()
    }
  },

  methods: {
    async create () {
      if (this.isLoading) return
      this.isLoading = true

      // TODO: check for validation

      try {
        const response = await createTemplate(this.formData)

        analytics.logEvent('templateCreated')

        const slug = this.slug
        this.clearForm()

        this.$router.push({
          name: 'Template',
          params: {
            templateId: response.data.templateId,
            slug: slug
          }
        })
      } catch (e) {
        // TODO: handle this
        console.log(e.response)
      } finally {
        this.isLoading = false
      }
    },

    loadSampleTemplate () {
      if (!confirm('Are you sure you want to load sample template')) {
        return
      }
      // add confirmation
      this.titleInput = sampleTitle
      this.descriptionInput = sampleDescription
      this.toInput = sampleTo
      this.subjectInput = sampleSubject
      this.messageInput = sampleMessage
    },

    clearForm () {
      this.titleInput = ''
      this.descriptionInput = ''
      this.toInput = ''
      this.subjectInput = ''
      this.messageInput = ''
    }
  }
})
</script>
